<template>
	<div class="pagination" :class="align" v-if="currentPage">
		<button
			class="btn-prev"
			@click="prevPage()"
			:disabled="currentPage === 1"
		>
			<img src="@/assets/img/icon/left.svg" alt="" />
		</button>
		<span
			class="page-item separator"
			v-if="arrPages[0] > 9"
			@click="changePage(arrPages[0] - 1)"
		>
			...
		</span>
		<span
			class="page-item"
			v-for="n in arrPages"
			:key="n"
			@click="changePage(n)"
			:class="{ active: currentPage == n }"
		>
			{{ n }}
		</span>
		<span
			class="page-item separator"
			v-if="arrPages[arrPages.length - 1] < totalPage"
			@click="changePage(arrPages[arrPages.length - 1] + 1)"
		>
			...
		</span>
		<button
			class="btn-next"
			@click="nextPage()"
			:disabled="currentPage === totalPage"
		>
			<img src="@/assets/img/icon/right.svg" alt="" />
		</button>
	</div>
</template>

<script>
export default {
	name: 'Pagination',
	props: {
		align: {
			type: String,
			default: 'left',
		},
		totalRows: {
			type: Number,
		},
		perPage: {
			type: Number,
		},
		currentPage: {
			type: Number,
			default: 1,
		},
	},
	data() {
		return {}
	},
	computed: {
		totalPage() {
			return Math.ceil(this.totalRows / this.perPage)
		},
		arrPages() {
			const tempArr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
			let num = Math.floor(this.currentPage / 10)
			let output = undefined
			if (num == 0) {
				output = tempArr.slice(1, tempArr.length)
			} else {
				output = tempArr.map((item) => Number(num.toString() + item))
			}
			if (output[output.length - 1] <= this.totalPage) {
				return output
			}
			return output.slice(0, output.indexOf(this.totalPage) + 1)
		},
	},
	methods: {
		changePage(n) {
			this.$emit('changePage', n)
		},
		prevPage() {
			let page = Number(this.currentPage)
			if (this.currentPage > 1) {
				page = Number(page) - 1
			}
			this.$emit('changePage', page)
		},
		nextPage() {
			let page = this.currentPage
			if (this.currentPage < Number(this.totalPage)) {
				page = Number(page) + 1
			}
			this.$emit('changePage', page)
		},
	},
}
</script>

<style lang="scss" scoped>
.pagination {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	&.center {
		justify-content: center;
	}
	&.right {
		justify-content: flex-end;
	}
	button {
		border: solid 1px #454348;
		border-radius: 4px;
		padding: 5px 10px;
		font-family: 'Montserrat';
		font-size: 14px;
		font-weight: 600;
		display: inline-block;
		width: 30px;
		height: 30px;
		text-align: center;
		&.btn-prev {
			margin-right: 5px;
		}
		&.btn-next {
			margin-left: 5px;
		}
		img {
			width: 10px;
		}
		&:disabled {
			opacity: 0.6;
		}
	}
	.page-item {
		display: inline-block;
		width: auto;
		min-width: 30px;
		height: 30px;
		text-align: center;
		margin: 5px;
		border: solid 1px #454348;
		border-radius: 4px;
		padding: 5px 10px;
		font-family: 'Montserrat';
		color: #454348;
		font-size: 14px;
		font-weight: 600;
		opacity: 0.6;
		&:hover {
			cursor: pointer;
			opacity: 1;
		}
		&.active {
			opacity: 1;
			color: #6c38ef;
			border-color: #6c38ef;
		}
		&.separator {
			font-size: 10px;
		}
	}
}
@include media-max(768px) {
	.pagination {
		button {
			border-radius: 0;
			border: solid 1px #454348;
			&.btn-prev {
				margin-right: 0;
				border-top-left-radius: 4px;
				border-bottom-left-radius: 4px;
				border-right: 0;
			}
			&.btn-next {
				margin-left: 0;
				border-top-right-radius: 4px;
				border-bottom-right-radius: 4px;
				border-left: solid 1px #454348;
			}
		}
		.page-item {
			margin-left: 0;
			margin-right: 0;
			border-left: solid 1px #454348;
			border-right: 0;
			border-radius: 0;
			&:hover {
				color: #454348;
			}
			&.active {
				background: #6c38ef;
				color: #fff;
			}
		}
	}
}
@include media-max(500px) {
	.pagination {
		button {
			font-size: 12px;
			font-weight: 400;
			width: 28px;
			height: 28px;
		}
		.page-item {
			font-size: 12px;
			font-weight: 400;
			min-width: 28px;
			height: 28px;
		}
	}
}
</style>
